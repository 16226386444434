import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Login from './Login';
import Home from './Home';
import Users from './Users';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebaseConfig from './firebase-config.json';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);

function Notification(props) {
  return (
    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={props.open} autoHideDuration={props.duration} onClose={props.handleClose}>
      <Alert onClose={props.handleClose} severity={props.severity} sx={{ width: "100%" }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

function getNotificationObject(open, duration, severity, message) {
  open = open == null ? false : open;
  duration = duration == null ? 3000 : duration;
  severity = severity == null ? "success" : severity;
  message = message == null ? "Test" : message;

  return {
    open: open,
    duration: duration,
    severity: severity,
    message: message
  }
}

function App() {
  const [user, setUser] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [admin, setAdmin] = React.useState(false);
  const [notification, setNotification] = React.useState(getNotificationObject());

  const handleNotificationClose = () => {
    let newNotification = JSON.parse(JSON.stringify(notification));

    newNotification.open = false;

    setNotification(newNotification);
  };

  const notify = React.useCallback((duration, severity, message) => {
    setNotification(getNotificationObject(true, duration, severity, message));
  });

  return (
    <Router>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AppBar position="sticky">
              <Container>
                <Toolbar disableGutters>
                  <Grid item xs={2}>
                    <Container>
                      <Typography variant="h6">
                        Challenge Labs
                      </Typography>
                    </Container>
                  </Grid>
                  <Grid item xs={7}>
                    <Container>
                      <Button color="inherit" component={NavLink} to = "/">
                        Home
                      </Button>
                      {admin &&
                        <Button color="inherit" component={NavLink} to = "/users">
                        Users
                        </Button>
                      }
                    </Container>
                  </Grid>
                  <Grid item xs={3}>
                    <Container>
                      <Login auth={firebaseAuth} user={user} setUser={setUser} token={token} setToken={setToken} admin={admin} setAdmin={setAdmin} notify={notify} />
                    </Container>
                  </Grid>
                </Toolbar>
              </Container>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <Routes>
                <Route path="/" element={<Home user={user} token={token} admin={admin} notify={notify} />}></Route>
                <Route path="/users" element={<Users token={token} notify={notify} />}></Route>
              </Routes>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Notification open={notification.open} duration={notification.duration} severity={notification.severity} message={notification.message} handleClose={handleNotificationClose} />
    </Router>
  );
}

export default App;
